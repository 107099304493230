import { defineMessage } from '@lingui/macro'
import {
  MESSAGE_PRICING_BASE,
  MESSAGE_PRICING_EXTRA,
  MESSAGE_PRICING_TRANSLATION
} from '@slc/constants'

export const messages = {
  [MESSAGE_PRICING_BASE]: defineMessage({ id: 'constants.message.pricing.base' }),
  [MESSAGE_PRICING_EXTRA]: defineMessage({ id: 'constants.message.pricing.extra' }),
  [MESSAGE_PRICING_TRANSLATION]: defineMessage({ id: 'constants.message.pricing.translation' })
}
