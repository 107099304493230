import { defineMessage } from '@lingui/macro'
import {
  CREATED_QUOTE,
  ACCEPTED_QUOTE,
  CANCELLED_QUOTE
} from '@slc/constants'

export const messages = {
  [CREATED_QUOTE]: defineMessage({ id: 'constants.quote.status.created' }),
  [ACCEPTED_QUOTE]: defineMessage({ id: 'constants.quote.status.accepted' }),
  [CANCELLED_QUOTE]: defineMessage({ id: 'constants.quote.status.cancelled' })
}
