import { defineMessage } from '@lingui/macro'
import {
  DOWNLOAD_SUPPORT,
  AUDIOCD_SUPPORT,
  CDROM_SUPPORT,
  TAPE_SUPPORT
} from '@slc/constants'

export const messages = {
  [DOWNLOAD_SUPPORT]: defineMessage({ id: 'constants.message.format.download' }),
  [AUDIOCD_SUPPORT]: defineMessage({ id: 'constants.message.format.audiocd' }),
  [CDROM_SUPPORT]: defineMessage({ id: 'constants.message.format.cdrom' }),
  [TAPE_SUPPORT]: defineMessage({ id: 'constants.message.format.tape' })
}
