import { defineMessage } from '@lingui/macro'
import {
  FUNNEL_ORDER_TYPE,
  CREDIT_ORDER_TYPE,
  GSM_ORDER_TYPE,
  DNS_ORDER_TYPE,
  MIXING_ORDER_TYPE
} from '@slc/constants'

export const messages = {
  [FUNNEL_ORDER_TYPE]: defineMessage({ id: 'constants.order.type.funnel' }),
  [CREDIT_ORDER_TYPE]: defineMessage({ id: 'constants.order.type.credit' }),
  [GSM_ORDER_TYPE]: defineMessage({ id: 'constants.order.type.gsm' }),
  [DNS_ORDER_TYPE]: defineMessage({ id: 'constants.order.type.dns' }),
  [MIXING_ORDER_TYPE]: defineMessage({ id: 'constants.order.type.mixing' })
}
