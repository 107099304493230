import { defineMessage } from '@lingui/macro'
import {
  WAITING_MESSAGE_STATUS,
  IN_PRODUCTION_MESSAGE_STATUS,
  RETAKE_MESSAGE_STATUS,
  COMPLETED_MESSAGE_STATUS,
  CANCELLED_MESSAGE_STATUS
} from '@slc/constants'

export const messages = {
  [WAITING_MESSAGE_STATUS]: defineMessage({ id: 'constants.message.status.waiting' }),
  [IN_PRODUCTION_MESSAGE_STATUS]: defineMessage({ id: 'constants.message.status.in_production' }),
  [RETAKE_MESSAGE_STATUS]: defineMessage({ id: 'constants.message.status.retake' }),
  [COMPLETED_MESSAGE_STATUS]: defineMessage({ id: 'constants.message.status.completed' }),
  [CANCELLED_MESSAGE_STATUS]: defineMessage({ id: 'constants.message.status.cancel' })
}
