import { defineMessage } from '@lingui/macro'
import {
  ORDER_CONTACT_SUBJECT,
  STUDIO_CONTACT_SUBJECT,
  ISSUE_CONTACT_SUBJECT,
  QUOTE_CONTACT_SUBJECT,
  QUESTION_CONTACT_SUBJECT,
  OTHER_CONTACT_SUBJECT
} from '@slc/constants'

export const messages = {
  [ORDER_CONTACT_SUBJECT]: defineMessage({ id: 'constants.contact.subject.order' }),
  [STUDIO_CONTACT_SUBJECT]: defineMessage({ id: 'constants.contact.subject.studio' }),
  [ISSUE_CONTACT_SUBJECT]: defineMessage({ id: 'constants.contact.subject.issue' }),
  [QUOTE_CONTACT_SUBJECT]: defineMessage({ id: 'constants.contact.subject.quote' }),
  [QUESTION_CONTACT_SUBJECT]: defineMessage({ id: 'constants.contact.subject.question' }),
  [OTHER_CONTACT_SUBJECT]: defineMessage({ id: 'constants.contact.subject.other' })
}
