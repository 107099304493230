import { defineMessage } from '@lingui/macro'
import {
  TRANSLATION_WAITING_PRODUCTION_MESSAGE_STATUS,
  TRANSLATION_VALIDATION_PRODUCTION_MESSAGE_STATUS,
  TRANSLATION_VALIDATED_PRODUCTION_MESSAGE_STATUS,
  TRANSLATION_RETAKE_PRODUCTION_MESSAGE_STATUS,

  VOICE_WAITING_PRODUCTION_MESSAGE_STATUS,
  VOICE_VALIDATION_PRODUCTION_MESSAGE_STATUS,
  VOICE_VALIDATED_PRODUCTION_MESSAGE_STATUS,
  VOICE_RETAKE_PRODUCTION_MESSAGE_STATUS
} from '@slc/constants'

export const messages = {
  [TRANSLATION_WAITING_PRODUCTION_MESSAGE_STATUS]: defineMessage({ id: 'constants.production.status.translation_waiting' }),
  [TRANSLATION_VALIDATION_PRODUCTION_MESSAGE_STATUS]: defineMessage({ id: 'constants.production.status.translation_validation' }),
  [TRANSLATION_VALIDATED_PRODUCTION_MESSAGE_STATUS]: defineMessage({ id: 'constants.production.status.translation_validated' }),
  [TRANSLATION_RETAKE_PRODUCTION_MESSAGE_STATUS]: defineMessage({ id: 'constants.production.status.translation_retake' }),

  [VOICE_WAITING_PRODUCTION_MESSAGE_STATUS]: defineMessage({ id: 'constants.production.status.voice_waiting' }),
  [VOICE_VALIDATION_PRODUCTION_MESSAGE_STATUS]: defineMessage({ id: 'constants.production.status.voice_validation' }),
  [VOICE_VALIDATED_PRODUCTION_MESSAGE_STATUS]: defineMessage({ id: 'constants.production.status.voice_validated' }),
  [VOICE_RETAKE_PRODUCTION_MESSAGE_STATUS]: defineMessage({ id: 'constants.production.status.voice_retake' })
}
