import { defineMessage } from '@lingui/macro'
import {
  SUBTOTAL_ORDER_LINE,
  DISCOUNT_ORDER_LINE,
  TAXES_ORDER_LINE,
  TOTAL_ORDER_LINE
} from '@slc/constants'

export const messages = {
  [SUBTOTAL_ORDER_LINE]: defineMessage({ id: 'constants.order.line.subtotal' }),
  [DISCOUNT_ORDER_LINE]: defineMessage({ id: 'constants.order.line.discount' }),
  [TAXES_ORDER_LINE]: defineMessage({ id: 'constants.order.line.taxes' }),
  [TOTAL_ORDER_LINE]: defineMessage({ id: 'constants.order.line.total' })
}
