import { defineMessage } from '@lingui/macro'
import {
  WAITING_PAYMENT_STATUS,
  CONFIRMATION_PAYMENT_STATUS,
  SUCCEED_PAYMENT_STATUS,
  FAILED_PAYMENT_STATUS,
  CANCELED_PAYMENT_STATUS,
  AUTHORIZATION_NEEDED_PAYMENT_STATUS,
  ERROR_PAYMENT_STATUS
} from '@slc/constants'

export const messages = {
  [WAITING_PAYMENT_STATUS]: defineMessage({ id: 'constants.order.payment.status.requires_payment_method' }),
  [CONFIRMATION_PAYMENT_STATUS]: defineMessage({ id: 'constants.order.payment.status.payment_confirmation' }),
  [SUCCEED_PAYMENT_STATUS]: defineMessage({ id: 'constants.order.payment.status.succeeded' }),
  [FAILED_PAYMENT_STATUS]: defineMessage({ id: 'constants.order.payment.status.cancelled' }),
  [CANCELED_PAYMENT_STATUS]: defineMessage({ id: 'constants.order.payment.status.payment_failed' }),
  [AUTHORIZATION_NEEDED_PAYMENT_STATUS]: defineMessage({ id: 'constants.order.payment.status.authorization_needed_payment_status' }),
  [ERROR_PAYMENT_STATUS]: defineMessage({ id: 'constants.order.payment.status.error' })
}
