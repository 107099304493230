import { defineMessage } from '@lingui/macro'
import {
  MESSAGE_SELECTION_TEXT,
  MESSAGE_SELECTION_FIRM,
  MESSAGE_SELECTION_BLOCK,
  MESSAGE_SELECTION_TRANSLATION,
  MESSAGE_SELECTION_ORIGIN
} from '@slc/constants'

export const messages = {
  [MESSAGE_SELECTION_TEXT]: defineMessage({ id: 'constants.message.selection.text' }),
  [MESSAGE_SELECTION_FIRM]: defineMessage({ id: 'constants.message.selection.firm' }),
  [MESSAGE_SELECTION_BLOCK]: defineMessage({ id: 'constants.message.selection.block' }),
  [MESSAGE_SELECTION_TRANSLATION]: defineMessage({ id: 'constants.message.selection.translation' }),
  [MESSAGE_SELECTION_ORIGIN]: defineMessage({ id: 'constants.message.selection.origin' }),
}
