import { defineMessage } from '@lingui/macro'
import {
  MESSAGE_ORDER_ITEM_TYPE,
  CART_OPTION_ORDER_ITEM_TYPE,
  OPTION_ORDER_ITEM_TYPE,
  FORMAT_ORDER_ITEM_TYPE,
  DISCOUNT_ORDER_ITEM_TYPE
} from '@slc/constants'

export const messages = {
  [MESSAGE_ORDER_ITEM_TYPE]: defineMessage({ id: 'constants.orderItem.type.message' }),
  [CART_OPTION_ORDER_ITEM_TYPE]: defineMessage({ id: 'constants.orderItem.type.cartOption' }),
  [OPTION_ORDER_ITEM_TYPE]: defineMessage({ id: 'constants.orderItem.type.option' }),
  [FORMAT_ORDER_ITEM_TYPE]: defineMessage({ id: 'constants.orderItem.type.format' }),
  [DISCOUNT_ORDER_ITEM_TYPE]: defineMessage({ id: 'constants.orderItem.type.discount' })
}
