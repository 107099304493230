import { defineMessage } from '@lingui/macro'
import {
  CREATED_ORDER,
  ACCEPTED_ORDER,
  CANCELLED_ORDER,
  WAITING_PAYMENT_ORDER,
  PAYMENT_CONFIRMED_ORDER,
  PAID_ORDER,
  PAYMENT_ISSUE_ORDER,
  PRODUCTION_ORDER,
  COMPLETE_ORDER,
  REFUND_ORDER
} from '@slc/constants'

export const messages = {
  [CREATED_ORDER]: defineMessage({ id: 'constants.order.status.created' }),
  [ACCEPTED_ORDER]: defineMessage({ id: 'constants.order.status.accepted' }),
  [CANCELLED_ORDER]: defineMessage({ id: 'constants.order.status.cancelled' }),
  [WAITING_PAYMENT_ORDER]: defineMessage({ id: 'constants.order.status.waiting_payment' }),
  [PAYMENT_CONFIRMED_ORDER]: defineMessage({ id: 'constants.order.status.payment_confirmed' }),
  [PAID_ORDER]: defineMessage({ id: 'constants.order.status.paid' }),
  [PAYMENT_ISSUE_ORDER]: defineMessage({ id: 'constants.order.status.payment_issue' }),
  [PRODUCTION_ORDER]: defineMessage({ id: 'constants.order.status.production' }),
  [COMPLETE_ORDER]: defineMessage({ id: 'constants.order.status.completed' }),
  [REFUND_ORDER]: defineMessage({ id: 'constants.order.status.refund' })
}
