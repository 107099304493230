import { defineMessage } from '@lingui/macro'
import {
  TOPUP_STATEMENT_TYPE,
  ORDER_STATEMENT_TYPE,
  REFUND_STATEMENT_TYPE
} from '@slc/constants'

export const messages = {
  [TOPUP_STATEMENT_TYPE]: defineMessage({ id: 'constants.statement.type.topup' }),
  [ORDER_STATEMENT_TYPE]: defineMessage({ id: 'constants.statement.type.order' }),
  [REFUND_STATEMENT_TYPE]: defineMessage({ id: 'constants.statement.type.refund' })
}
