import { defineMessage } from '@lingui/macro'
import {
  STANDARD_MESSAGE,
  SEMI_STANDARD_MESSAGE,
  CUSTOM_MESSAGE
} from '@slc/constants'

export const messages = {
  [STANDARD_MESSAGE]: defineMessage({ id: 'constants.message.category.standard' }),
  [SEMI_STANDARD_MESSAGE]: defineMessage({ id: 'constants.message.category.semiStandard' }),
  [CUSTOM_MESSAGE]: defineMessage({ id: 'constants.message.category.custom' })
}
