import { defineMessage } from '@lingui/macro'
import {
  VISITOR_CONTACT_PROFILE,
  CUSTOMER_CONTACT_PROFILE,
  RESELLER_CONTACT_PROFILE,
  VOICE_CONTACT_PROFILE
} from '@slc/constants'

export const messages = {
  [VISITOR_CONTACT_PROFILE]: defineMessage({ id: 'constants.contact.profile.visitor' }),
  [CUSTOMER_CONTACT_PROFILE]: defineMessage({ id: 'constants.contact.profile.customer' }),
  [RESELLER_CONTACT_PROFILE]: defineMessage({ id: 'constants.contact.profile.reseller' }),
  [VOICE_CONTACT_PROFILE]: defineMessage({ id: 'constants.contact.profile.voice' })
}
