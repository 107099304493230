import { defineMessage } from '@lingui/macro'
import {
  PREDECROCHE_MSG_TYPE,
  ATTENTE_MSG_TYPE,
  REPONDEUR_MSG_TYPE,
  GSM_MSG_TYPE,
  VOEUX_MSG_TYPE
} from '@slc/constants'

export const messages = {
  [PREDECROCHE_MSG_TYPE]: defineMessage({ id: 'constants.message.type.predecroche' }),
  [ATTENTE_MSG_TYPE]: defineMessage({ id: 'constants.message.type.attente' }),
  [REPONDEUR_MSG_TYPE]: defineMessage({ id: 'constants.message.type.repondeur' }),
  [GSM_MSG_TYPE]: defineMessage({ id: 'constants.message.type.gsm' }),
  [VOEUX_MSG_TYPE]: defineMessage({ id: 'constants.message.type.voeux' })
}
