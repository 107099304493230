import { defineMessage } from '@lingui/macro'
import {
  RESELLER_TYPE,
  PREMIUM_TYPE,
  CUSTOMER_TYPE
} from '@slc/constants'

export const messages = {
  [RESELLER_TYPE]: defineMessage({ id: 'constants.customer.type.reseller' }),
  [PREMIUM_TYPE]: defineMessage({ id: 'constants.customer.type.premium' }),
  [CUSTOMER_TYPE]: defineMessage({ id: 'constants.customer.type.customer' })
}
