import { defineMessage } from '@lingui/macro'
import {
  CARD_PAYMENT,
  TRANSFER_PAYMENT,
  CHECK_PAYMENT,
  CREDIT_PAYMENT
} from '@slc/constants'

export const messages = {
  [CARD_PAYMENT]: defineMessage({ id: 'constants.payment.type.card' }),
  [TRANSFER_PAYMENT]: defineMessage({ id: 'constants.payment.type.transfer' }),
  [CHECK_PAYMENT]: defineMessage({ id: 'constants.payment.type.check' }),
  [CREDIT_PAYMENT]: defineMessage({ id: 'constants.payment.type.credit' })
}
