import { defineMessage } from '@lingui/macro'
import {
  BASE_MESSAGE_ORDER_ITEM_TYPE,
  EXTRA_MESSAGE_ORDER_ITEM_TYPE,
  TRANSLATION_MESSAGE_ORDER_ITEM_TYPE,
  OPTION_MESSAGE_ORDER_ITEM_TYPE
} from '@slc/constants'

export const messages = {
  [BASE_MESSAGE_ORDER_ITEM_TYPE]: defineMessage({ id: 'constants.messageOrderItem.type.base' }),
  [EXTRA_MESSAGE_ORDER_ITEM_TYPE]: defineMessage({ id: 'constants.messageOrderItem.type.extra' }),
  [TRANSLATION_MESSAGE_ORDER_ITEM_TYPE]: defineMessage({ id: 'constants.messageOrderItem.type.translation' }),
  [OPTION_MESSAGE_ORDER_ITEM_TYPE]: defineMessage({ id: 'constants.messageOrderItem.type.messageOption' }),
}
