import { defineMessage } from '@lingui/macro'
import {
  MP3_FORMAT,
  WAV_44K_FORMAT,
  WAV_8K_16B_FORMAT,
  WAV_8K_8B_FORMAT,
  WAV_8K_8B_A_LAW_FORMAT
} from '@slc/constants'

export const messages = {
  [MP3_FORMAT]: defineMessage({ id: 'constants.message.format.mp3' }),
  [WAV_44K_FORMAT]: defineMessage({ id: 'constants.message.format.wav_44k' }),
  [WAV_8K_16B_FORMAT]: defineMessage({ id: 'constants.message.format.wav_8k16bits' }),
  [WAV_8K_8B_FORMAT]: defineMessage({ id: 'constants.message.format.wav_8k8bits' }),
  [WAV_8K_8B_A_LAW_FORMAT]: defineMessage({ id: 'constants.message.format.wav_8k8bits_Alaw' })
}
