import get from 'lodash/get'

export const getTranslationMessage = dict => name => {
  const message = get(dict, name, null)

  if (!message) {
    return ''
  }

  return message.id
}
