import { getTranslationMessage } from './helpers'

import {
  contactProfile,
  contactSubject,
  customerType,
  messageCategory,
  messageFormat,
  messageOption,
  messageOrderItemType,
  messagePricing,
  messageSelectionType,
  messageStatus,
  messageSupport,
  messageType,
  productionStatus,
  orderItemType,
  orderLineType,
  orderMessageItemType,
  orderPaymentStatus,
  orderStatus,
  orderType,
  paymentType,
  quoteStatus,
  statementType
} from './messages'

export const getContactProfile = getTranslationMessage(contactProfile)
export const getContactSubject = getTranslationMessage(contactSubject)
export const getCustomerType = getTranslationMessage(customerType)
export const getMessageCategory = getTranslationMessage(messageCategory)
export const getMessageFormat = getTranslationMessage(messageFormat)
export const getMessageOption = getTranslationMessage(messageOption)
export const getMessageOrderItemType = getTranslationMessage(messageOrderItemType)
export const getMessagePricing = getTranslationMessage(messagePricing)
export const getMessageSelectionType = getTranslationMessage(messageSelectionType)
export const getMessageStatus = getTranslationMessage(messageStatus)
export const getMessageSupport = getTranslationMessage(messageSupport)
export const getMessageType = getTranslationMessage(messageType)
export const getProductionStatus = getTranslationMessage(productionStatus)
export const getOrderItemType = getTranslationMessage(orderItemType)
export const getOrderLineType = getTranslationMessage(orderLineType)
export const getOrderMessageItemType = getTranslationMessage(orderMessageItemType)
export const getOrderPaymentStatus = getTranslationMessage(orderPaymentStatus)
export const getOrderStatus = getTranslationMessage(orderStatus)
export const getOrderType = getTranslationMessage(orderType)
export const getPaymentType = getTranslationMessage(paymentType)
export const getQuoteStatus = getTranslationMessage(quoteStatus)
export const getStatementType = getTranslationMessage(statementType)
