import { defineMessage } from '@lingui/macro'
import {
  GSM_DOWNLOAD_MSG_OPTION,
  CUSTOM_MUSIC_MSG_OPTION,
  MIXING_CART_OPTION,
  COMMERCIAL_RIGHTS_CART_OPTION,
  DIFFUSION_RIGHTS_CART_OPTION,
  PROOFREADING_CART_OPTION,
  RETAKE_CART_OPTION,
  EXPRESS_DELIVERY_CART_OPTION,
  THREE_YEAR_ARCHIVE_CART_OPTION
} from '@slc/constants'

export const messages = {
  [GSM_DOWNLOAD_MSG_OPTION]: defineMessage({ id: 'constants.message.options.gsmDownload' }),
  [CUSTOM_MUSIC_MSG_OPTION]: defineMessage({ id: 'constants.message.options.customMusic' }),

  [MIXING_CART_OPTION]: defineMessage({ id: 'constants.message.options.mixing' }),
  [COMMERCIAL_RIGHTS_CART_OPTION]: defineMessage({ id: 'constants.message.options.commercialRights' }),
  [DIFFUSION_RIGHTS_CART_OPTION]: defineMessage({ id: 'constants.message.options.diffusionRights' }),
  [RETAKE_CART_OPTION]: defineMessage({ id: 'constants.message.options.retake' }),
  [PROOFREADING_CART_OPTION]: defineMessage({ id: 'constants.message.options.proofreading' }),
  [EXPRESS_DELIVERY_CART_OPTION]: defineMessage({ id: 'constants.message.options.expressDelivery' }),
  [THREE_YEAR_ARCHIVE_CART_OPTION]: defineMessage({ id: 'constants.message.options.threeYearArchive' })
}
